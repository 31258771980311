<template>
  <section>
    <!-- view -->
    <div class="responsive-toggle" @click="onShowHideSideBar()">
      <span class="toggle-btn">
        <svg
          class="ml-3 w-6 h-6"
          fill="none"
          stroke="white"
          viewBox="0 0 25 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          ></path>
        </svg>
      </span>
    </div>

    <div id="view" :class="[{ collapsed: sideCollapsed }]">
      <AppTopbar
        :logo-visibility="sideCollapsed"
        :organizationLogo="organizationLogo"
      />
      <!-- <updates-dropdown-container /> -->
      <user-dropdown-container />
      <toast-notification />

      <main id="main" :class="`${mainClass || getMainClassDefaults} `">
        <slot></slot>
      </main>
    </div>

    <AppSidebar
      :class="'sb-farhan' + responive"
      :width="width"
      :widthCollapsed="widthCollapsed"
      :menu="menu"
      :collapsed="sideCollapsed"
      :theme="sidebarTheme"
      @item-click="onItemClick"
      @toggle-collapse="onCollapse"
    >
      <div slot="header" class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <img
            :src="organizationLogo"
            class="object-scale-down"
            style="height:50px;"
          />
        </div>
        <div class="flex items-center mt-px">
          <t-toggle variant="lightAndDarkMode" @change="switchTheme()" />
        </div>
      </div>
      <!-- <span slot="footer">footer</span> -->
      <span slot="toggle-icon">
        <svg
          class="ml-3 w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          ></path>
        </svg>
      </span>
      <span slot="dropdown-icon">
        <svg
          class="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </span>
    </AppSidebar>

    <!-- <CreditCardModal /> -->
    <PopSuggestion />
  </section>
</template>

<script>
import AppTopbar from '@/components/layout/topbar/AppTopbar'
import ToastNotification from '@/components/notification/ToastNotification'
import PopSuggestion from '@/components/suggestive-step/PopSuggestion.vue'

// import Sidebar from "@/components/layouts/Sidebar";

// import DropdownTrigger from "@/components/dropdown/DropdownTrigger";
// import DropdownContainer from "@/components/dropdown/DropdownContainer";

// import UserDropdownContainer from "@/components/UserDropdownContainer";
import AppSidebar from '@/components/layout/sidebar/SidebarMenu'
// import UpdatesDropdownContainer from "../../components/topbar/UpdatesDropdownContainer.vue";
import UserDropdownContainer from '@/components/layout/topbar/UserDropdownContainer.vue'
// import CreditCardModal from '@/composites/credit-card/CreditCardModal.vue'
import { useEndpoints } from '@/composables'
import { NavigationConfig } from '@/config/NavigationConfig'
import { GOOGLE_ANALYTICS } from '@/config/track-event.config'
import {
  STEP_SUGGESTIONS,
  STEP_TOPIC,
} from '@/components/suggestive-step/helper'

export default {
  name: 'BaseLayout',
  props: {
    mainClass: {
      type: String,
      default: '',
    },
  },
  components: {
    AppTopbar,
    AppSidebar,
    ToastNotification,
    // UpdatesDropdownContainer,
    UserDropdownContainer,
    // Sidebar,
    // DropdownTrigger,
    // DropdownContainer,
    // UpdatesDropdown,
    // CreditCardModal,
    PopSuggestion,
  },
  provide() {
    return {
      $sidebarCollapsed: this.sideCollapsed,
      $sidebarHidden: this.windowInnerWidth < 930,
      $mainClassDefaults: this.getMainClassDefaults,
    }
  },
  data() {
    return {
      width: '250px',
      widthCollapsed: '50px',
      windowWidth: window.innerWidth,
      menuScrollBar: null,
      responive: '',
      lightLogo: require('@/assets/otoride/logo/otoride-sidebar-logo-light-v2.png'),
      darkLogo: require('@/assets/otoride/logo/otoride-sidebar-logo-dark-v2.png'),
      organizationLogo: '',
      sideCollapsed: true,
      windowInnerWidth: null,
      menuList: [],
      collapsedMenu: [
        'Main Navigation',
        'Live Map',
        'Riders',
        'Vehicles',
        'Trips',
        'Transactions',
        'Settings',
      ],
      restrictedMenuItemTitle: [
        'IOT Capabilities',
        'IOT',
        'Blacklisted',
        'Subscription Plans',
        'Deleted Riders',
      ],
      vehicleTypeMap: {
        'SCOOTER': {
          text: 'Scooters',
        },
        'SCOOTER PRO': {
          text: 'Scooters Pro',
        },
        'EBIKE': {
          text: 'E-Bikes',
        },
        'BIKE': {
          text: 'Bikes',
        },
        'COCO': {
          text: 'SuperCocos',
        },
        'KENOTA': {
          text: 'Kenotas',
        },
        'MOPED': {
          text: 'Mopeds',
        },
      },
    }
  },
  computed: {
    // sideCollapsed: function() {
    //   return this.$store.getters['navigation/getSidebarCollapsed']
    // },
    sidebarTheme: function() {
      return this.$store.getters['navigation/getSidebarTheme']
    },
    menu: function() {
      if (!this.$store.getters['auth/userInfo'].is_superadmin) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.menuList = this.$store.getters['navigation/getMenu'].map(
          (item) => {
            if (item.child) {
              const itemChild = item.child.filter(
                (item) =>
                  this.restrictedMenuItemTitle.includes(item.title) !== true
              )
              return {
                title: item.title,
                permId: item.permId,
                key: item.key,
                icon: item.icon,
                child: [...itemChild],
              }
            } else {
              return item
            }
          }
        )
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.menuList = NavigationConfig.sideMenu
      }

      if (this.sideCollapsed) {
        const filteredMenu = this.menuList.filter((item) =>
          this.collapsedMenu.includes(item.title)
        )

        let sortedMenu = []

        this.collapsedMenu.map((sortedMenuItem) => {
          filteredMenu.map((filteredMenuItem) => {
            if (filteredMenuItem.title === sortedMenuItem)
              sortedMenu.push(filteredMenuItem)
          })
        })

        return sortedMenu
      } else {
        console.log('menuList', this.menuList)
        return this.menuList
      }
    },
    isLoading: function() {
      return this.$store.getters['navigation/isLoading']
    },
    userRoleInfo: function() {
      return this.$store.getters['auth/roleInfo']
    },
    permissionsInfo: function() {
      return this.$store.getters['auth/permissionsInfo']
    },
    getLogoSrc() {
      //get logo appearance dynamically
      if (this.sidebarTheme === 'light-theme') {
        return this.darkLogo
      }
      return this.lightLogo
    },
    getMainClassDefaults() {
      const yFactor = 'mt-3 '
      // 930px is defined in the style section (in this file)
      // due to the sidebar scrollbar is scoped in the main view, left padding should cover it
      const xFactor =
        this.windowInnerWidth <= 930
          ? 'px-4 '
          : this.sideCollapsed
          ? 'pl-6 pr-4 pb-3 '
          : 'px-4 '

      return xFactor + yFactor
    },
    isSmallScreen() {
      if (this.windowWidth <= 930) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.$store.commit('screen/SET_SMALL_SCREEN_STATUS', this.isSmallScreen)
    if (!this.$store.getters['auth/userInfo'].is_superadmin) {
      this.onCheckStepForStartingBusiness()
    }
  },
  async mounted() {
    this.onSetOrgLogo()

    this.$nextTick(() => {
      const objToArray = Object.keys(this.vehicleTypeMap)
      const outVehicleType = objToArray.filter(
        (vt) =>
          !this.$store.getters['auth/organizationInfo'].vehicle_type.includes(
            vt
          )
      )
      console.log('outVehicleType', outVehicleType)
      outVehicleType.map((vt) =>
        this.restrictedMenuItemTitle.push(this.vehicleTypeMap[vt].text)
      )

      window.addEventListener('resize', this.onResize)
      console.log('outVehicleType-res', this.restrictedMenuItemTitle)
    })

    const permissions = this.permissionsInfo.map((x) => {
      return x.name
    })
    this.$store.dispatch('navigation/setMenuPayloadForPerms', permissions)

    window.removeEventListener('resize', this.setWindowInnerWidth)
    window.addEventListener('resize', this.setWindowInnerWidth)
    this.setWindowInnerWidth()

    // default
    // const defPaymentMethod = this.$org?.default_subscription_payment_method

    // if (!defPaymentMethod) {
    //   // user is already logged in, we don't have the updated data in the vuex
    //   // which was stored previously during successful login attempt
    //   await this.fetchUserInfo().then((userRes) => {
    //     const { data } = userRes
    //     console.log(
    //       'userRes',
    //       data,
    //       'isSuperAdminOrg',
    //       isSuperAdminOrg,
    //       'defPaymentMethod',
    //       defPaymentMethod
    //     )
    //     let mutateRole, mutatePermissions
    //     if (
    //       data.user.is_superadmin &&
    //       data.user.organization_role === 'Owner'
    //     ) {
    //       mutateRole = data.role
    //       mutateRole.permissions.push({
    //         id: 101,
    //         name: 'Organizations',
    //         action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
    //       })
    //       mutatePermissions = data.permissions
    //       mutatePermissions.push({
    //         id: 101,
    //         name: 'Organizations',
    //         action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
    //       })
    //     } else if (
    //       !data.user.is_superadmin &&
    //       data.user.organization_role === 'Owner'
    //     ) {
    //       mutateRole = data.role
    //       mutateRole.permissions.push({
    //         id: 101,
    //         name: 'Organizations',
    //         action_types: ['VIEW', 'UPDATE'],
    //       })
    //       mutatePermissions = data.permissions
    //       mutatePermissions.push({
    //         id: 101,
    //         name: 'Organizations',
    //         action_types: ['VIEW', 'UPDATE'],
    //       })
    //     } else {
    //       mutateRole = data.role
    //       mutatePermissions = data.permissions
    //     }
    //     const resData = {
    //       api_token: this.$store.getters['auth/accessToken'],
    //       organization: data.organization,
    //       profile: data.profile,
    //       role: mutateRole,
    //       user: data.user,
    //       roles: data.roles,
    //       permissions: mutatePermissions,
    //     }
    //     // api token is not provided with user info
    //     // data.api_token = this.$store.getters['auth/accessToken']
    //     // console.log('fetchUserInfoRes', userRes.data)
    //     // save the latest fetched data in vuex
    //     this.$store.commit('auth/AUTH_REMEMBER', resData)

    //     // don't show pop for the super admins
    //     const isSuperAdminOrg =
    //       data?.organization?.is_superadmin_org ?? this.$org?.is_superadmin_org
    //     const isSuperAdminUser =
    //       data.user?.is_superadmin ?? this.$user?.is_superadmin

    //     if (isSuperAdminOrg || isSuperAdminUser) return

    //     // show pop if user has no payment method
    //     // console.log(
    //     //   'hasCard',
    //     //   data.organization.default_subscription_payment_method
    //     // )
    //     // if (data.organization.default_subscription_payment_method === null) {
    //     //   this.$modal.show('CreditCardModal')
    //     // }
    //   })
    // }
  },
  methods: {
    onCheckStepForStartingBusiness() {
      this.$store.commit(
        'suggestiveStep/SET_HEADLINE',
        `${this.$t('components.suggestionStep.startingBusiness.headline')}`
      )
      this.$store.commit(
        'suggestiveStep/SET_STEP_TOPIC',
        STEP_TOPIC.STARTING_BUSINESS
      )

      this.$store.commit(
        'suggestiveStep/SET_SUGGESTIONS',
        STEP_SUGGESTIONS[STEP_TOPIC.STARTING_BUSINESS]
      )
      this.$store.commit(
        'suggestiveStep/SET_ORG_ID',
        this.$store.getters['auth/organizationInfo'].id || null
      )
      this.$store
        .dispatch('suggestiveStep/havePendingStepExist')
        .then((res) => {
          this.$store.commit(
            'suggestiveStep/SET_POPUP_VIEW_STATUS',
            res.isExist
          )
        })
    },
    onResize() {
      this.windowWidth = window.innerWidth
      this.$store.commit('screen/SET_SMALL_SCREEN_STATUS', this.isSmallScreen)
    },
    onSetOrgLogo() {
      this.$http
        .get(
          useEndpoints.organization.details(
            this.$store.getters['auth/organizationInfo'].id
          )
        )
        .then((res) => {
          console.log('resDataOrg', res)
          this.organizationLogo = res.data.avatar
        })
        .catch((err) => {
          this.organizationLogo = JSON.parse(
            localStorage.getItem('organization')
          ).avatar

          console.log('err-org', err)
        })
    },
    onItemClick(e, i) {
      console.log(e + i)
      if (window.gtag && i.href) {
        window.gtag('event', GOOGLE_ANALYTICS.OTO_ADMIN_SIDEBAR_NAVIGATE.TAG, {
          event_category: GOOGLE_ANALYTICS.OTO_ADMIN_SIDEBAR_NAVIGATE.CATEGORY,
          event_label: i.title,
          path: i.href,
        })
      }
    },
    onCollapse(c) {
      console.log(c)
      // this.$store.dispatch('navigation/toggleSidebarCollapsed', c)
      this.sideCollapsed = c
    },
    onShowHideSideBar() {
      if (this.sideCollapsed == true) {
        this.responive = ' disappered'
      } else {
        this.responive = ' not-disappered'
      }
      let c = !this.sideCollapsed
      // this.$store.dispatch('navigation/toggleSidebarCollapsed', c)
      this.sideCollapsed = c
    },
    switchTheme() {
      if (this.sidebarTheme === 'dark-theme') {
        this.$store.dispatch('navigation/toggleSidebarTheme', 'light-theme')
      } else {
        this.$store.dispatch('navigation/toggleSidebarTheme', 'dark-theme')
      }
    },
    setWindowInnerWidth() {
      this.windowInnerWidth = window.innerWidth || 0
    },

    async fetchUserInfo() {
      return await this.$http
        .get(useEndpoints.auth.userInfo())
        .then((userRes) => userRes)
        .catch((e) => {
          console.warn(e)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Server Error`,
            text: 'Failed to retrieve user data.',
          })
        })
    },
  },
}
</script>

<style>
#view {
  /*  additional 17px for scrollbar */
  padding-left: 250px;
  transition: padding 0.1s ease;
}

#view.collapsed {
  /*  additional 17px for scrollbar */
  /* todo: remove 17px from header */
  padding-left: 50px;
  transition: padding 0.1s ease;
}
@media (max-width: 930px) {
  #view {
    /*  additional 17px for scrollbar */
    padding-left: 0px;
    transition: padding 0.1s ease;
  }
  #view.collapsed {
    /*  additional 17px for scrollbar */
    /* todo: remove 17px from header */
    padding-left: 0px !important;
    transition: padding 0.1s ease;
  }
}
/* .sidebar.v-sidebar-menu .vsm-arrow:after {
  content: '\f105';
  font-family: 'FontAwesome';
}
.sidebar.v-sidebar-menu .collapse-btn:after {
  content: '\f07e';
  font-family: 'FontAwesome';
} */

.disappeared {
  display: none;
  transition: padding 0.05s ease-out;
}
.not-disappeared {
  display: block;
  transition: padding 0.05s ease-in;
}
.responsive-toggle {
  display: none;
}
.toggle-btn {
  margin-top: 15px;
}
@media only screen and (max-width: 930px) {
  .v-sidebar-menu.vsm_collapsed {
    display: none;
  }

  .responsive-toggle {
    position: fixed;
    z-index: 250;
    width: 55px;
    height: 55px;
    display: flex;
    background: #1c1d28;
  }
}
.pl-side-collapsed {
  margin-left: 1.5rem !important;
}
</style>
